import React, { FC } from 'react';
import { useGlobalState } from '../stores/GlobalStore';
import useEhrStateHandlers from '../hooks/useEhrStateHandlers';
import { Screens } from '../types';
import './App.less';
import SimpleLoading from './loading/SimpleLoading';

const App: FC = () => {
  useEhrStateHandlers();
  const { state } = useGlobalState();

  const { url, screen } = state;

  const CloverAssistContent = () => {
    return <>{url ? <iframe className="widget-frame-hub" src={url} /> : <ErrorLoadIframe />}</>;
  };

  const modesDisplay = {
    [Screens.None]: <div />,
    [Screens.Loading]: (
      <div>
        <SimpleLoading />
      </div>
    ),
    [Screens.CloverAssist]: (
      <div className="frame-container-hub">
        <CloverAssistContent />
      </div>
    ),
  };

  return modesDisplay[screen];
};

const ErrorLoadIframe: FC = () => (
  <div className="error-load-iframe">
    <div className="error-load-iframe-title">Something went wrong :(</div>
    <div className="error-load-iframe-text">Please refresh the page and try again</div>
    <img
      className="error-load-iframe-img"
      src="https://static.getvim.com/img/errorPageImg.png"
      alt="loading logo"
    />
  </div>
);

export default App;
